import React from 'react';
import { useNavigate } from 'react-router-dom';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

// get_form_details filedata_repo
export default function ClientFundingYearFormDetailsAllECF(props) {
    const clientAPI = props.clientAPI;
    const clientId = props.clientId;
    const kyList = props.kyList;
    const searchType = props.searchType;
    const [formData, setFormData] = React.useState(null);

    React.useEffect(() => {
        const getFormData = async () => {
            const formDataVar = await clientAPI.GetClientECFData(searchType, kyList);
            if (!formDataVar) return;
            setFormData(formDataVar[0]);
            console.log('[ClientFundingYearFormDetailsAllECF][useEffect][getFormData] formDataVar:', formDataVar);
            // return formDataVar;
        };

        getFormData();
    }, [clientAPI, kyList, searchType]);

    // Don't display anything until we have data.
    if (!formData) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <CircularProgress />
                <Typography variant='body1' sx={{ color: 'gray', marginTop: '10px', textAlign: 'center' }}>
                    <em>{`Gathering data for E-Rate FRN Detail...`}</em>
                </Typography>
            </Box>
        );
    }

    const parseMoney = (value) => {
        if (!value || value == null) {
            return 0;
        }
        if (typeof value === 'number') {
            return value;
        }

        if (value.includes('%')) {
            return parseFloat(value.replace('%', '')) / 100;
        }
        return parseFloat(value.replace(/[$,]/g, ''));
    };

    const formatMoney = (value) => `$${value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;

    const formatDate = (dateString) => {
        if (!dateString) {
            return '';
        }

        const [year, month, day] = dateString.split('-');
        return `${month}/${day}/${year}`;
    };

    return (
        <Box sx={{ fontSize: '13px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <h2>E-Rate FRN Detail</h2>
            </Box>

            <Box>
                <TableContainer>
                    <Table sx={{ width: '46.5%', tableLayout: 'fixed' }} size='small'>
                        <TableHead>
                            <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                                <TableCell sx={{ padding: '4px 8px', fontSize: '13px' }}>
                                    FRN: <span style={{ fontWeight: 'normal' }}>{formData.frn}</span>
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px', fontSize: '13px' }}>
                                    FRN Nickname:{' '}
                                    <span style={{ fontWeight: 'normal' }}>{formData.fundingrequestnickname}</span>
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px', fontSize: '13px' }}>
                                    Filing Window:
                                    <Link
                                        href={`/client-list/funding-history-details/${clientId}/${formData.billedentitynumber}/ECF/entNum`}
                                        rel='noreferrer'
                                        sx={{
                                            textDecoration: 'none',
                                            fontWeight: 'normal',
                                            marginLeft: 0.5,
                                        }}
                                    >
                                        {formData.filingwindow}
                                    </Link>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                </TableContainer>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, marginTop: 2, fontSize: '13px' }}>
                <Box id='client-info' sx={{ flex: 2.5 }}>
                    <h4 style={{ margin: 0 }}>
                        Applicant:
                        <Link
                            href={`/client-list/contact-search-report/${clientId}/${formData.billedentitynumber}/${searchType}`}
                            rel='noreferrer'
                            sx={{
                                textDecoration: 'none',
                                fontWeight: 'normal',
                                marginLeft: 1,
                            }}
                        >
                            {formData.applicantname}
                        </Link>
                    </h4>
                    <h4 style={{ margin: 0 }}>
                        Billed Entity Number:
                        <Link
                            href={`/client-list/funding-summary/${clientId}/${formData.billedentitynumber}/entNum`}
                            rel='noreferrer'
                            sx={{ textDecoration: 'none', fontWeight: 'normal', marginLeft: 1 }}
                        >
                            {formData.billedentitynumber}
                        </Link>
                    </h4>
                    <h4 style={{ margin: 0 }}>
                        Type: <span style={{ fontWeight: 'normal', marginLeft: 1 }}>{formData.applicanttype}</span>
                    </h4>
                </Box>
            </Box>

            <Box id='f470-f471-info' sx={{ marginTop: 2, fontSize: '13px', maxWidth: '46.5%' }}>
                <h4 style={{ margin: 0 }}>
                    471#: <span style={{ fontWeight: 'normal', marginLeft: 1 }}>{formData.applicationnumber}</span>
                </h4>

                <h4 style={{ margin: 0 }}>
                    SPIN:
                    <Link
                        href={`/client-list/funding-summary/${clientId}/${kyList}/${formData.spin}/spin`}
                        rel='noreferrer'
                        sx={{ textDecoration: 'none', fontWeight: 'normal', marginLeft: 1 }}
                    >
                        {formData.spin}
                    </Link>
                </h4>

                <h4 style={{ margin: 0 }}>
                    Service Type: <span style={{ fontWeight: 'normal', marginLeft: 1 }}>{formData.servicetype}</span>
                </h4>

                <h4 style={{ margin: 0 }}>
                    Wave: <span style={{ fontWeight: 'normal', marginLeft: 1 }}>{formData.obligationfile}</span>
                </h4>

                <h4 style={{ margin: 0 }}>
                    FCDL Date:{' '}
                    <span style={{ fontWeight: 'normal', marginLeft: 1 }}>{formatDate(formData.fcdldate)}</span>
                </h4>

                <h4 style={{ margin: 0 }}>
                    Status: <span style={{ fontWeight: 'normal', marginLeft: 1 }}>{formData.fundingrequeststatus}</span>
                </h4>

                <h4 style={{ margin: 0 }}>
                    Status Memo:
                    <span
                        style={{
                            fontWeight: 'normal',
                            display: 'block',
                            wordWrap: 'break-word',
                            whiteSpace: 'normal',
                            maxWidth: '100%', // 100% of the parent element (46.5%)
                        }}
                    >
                        {formData.fcdlcommentforfrn || formData.fcdlcommentforfrn ? (
                            <ul style={{ listStyleType: 'none', paddingLeft: '50px', margin: '5px 0 0' }}>
                                {formData.fcdlcommentforfrn && (
                                    <li style={{ textIndent: '-10px', marginBottom: '4px' }}>
                                        <span style={{ textIndent: '10px' }}>- {formData.fcdlcommentforfrn}</span>
                                    </li>
                                )}
                                {/* {formData.fcdlcommentforfrn && (
                                    <li style={{ textIndent: '-10px' }}>
                                        <span style={{ textIndent: '10px' }}>- {formData.fcdlcommentforfrn}</span>
                                    </li>
                                )} */}
                            </ul>
                        ) : (
                            <span>No comments available.</span>
                        )}
                    </span>
                </h4>
            </Box>

            <Box sx={{ display: 'flex', marginTop: 2, fontSize: '13px' }}>
                <Box id='service-info' sx={{ flex: 1, marginRight: '8px' }}>
                    <h4 style={{ margin: 0 }}>
                        Service Start Date (471):{' '}
                        <span style={{ fontWeight: 'normal' }}>{formatDate(formData.servicestartdate)}</span>
                    </h4>

                    <h4 style={{ margin: 0 }}>
                        Current Commitment:{' '}
                        <span style={{ fontWeight: 'normal' }}>
                            {formatMoney(parseFloat(formData.current_frntotalcharges))}
                        </span>
                    </h4>

                    <h4 style={{ margin: 0 }}>
                        Disbursed Amount*:{' '}
                        <span style={{ fontWeight: 'normal' }}>
                            {formatMoney(parseFloat(formData.frnauthorizeddisbursement))}
                        </span>
                    </h4>

                    <h4 style={{ margin: 0 }}>
                        Last Date of Service:{' '}
                        <span style={{ fontWeight: 'normal' }}>{formatDate(formData.serviceenddate)}</span>
                    </h4>
                </Box>

                <Box id='service-info-2' sx={{ flex: 2.5 }}>
                    <h4 style={{ margin: 0 }}>
                        Payment Mode: <span style={{ fontWeight: 'normal' }}>{formData.invoicingmethod}</span>
                    </h4>

                    <h4 style={{ margin: 0 }}>
                        Undisbursed:{' '}
                        <span style={{ fontWeight: 'normal' }}>
                            {formData.current_frntotalcharges === ''
                                ? ''
                                : formData.frnauthorizeddisbursement === null ||
                                  parseMoney(formData.frnauthorizeddisbursement) < 0
                                ? formData.current_frntotalcharges
                                : formatMoney(
                                      parseFloat(formData.current_frntotalcharges) -
                                          parseFloat(formData.frnauthorizeddisbursement)
                                  )}
                        </span>
                    </h4>

                    <h4 style={{ margin: 0 }}>
                        Last Date to Invoice:{' '}
                        <span style={{ fontWeight: 'normal' }}>{formatDate(formData.invoicedeadlinedate)}</span>
                    </h4>
                </Box>
            </Box>

            <Box sx={{ marginTop: 2 }}>
                <ClientFundingYearFormDetailsAllECFSection formData={formData} formatMoney={formatMoney} />
            </Box>
        </Box>
    );
}

function ClientFundingYearFormDetailsAllECFSection({ formData, formatMoney }) {
    if (!formData) {
        return null;
    }

    const frnData = [
        {
            label: 'Total Monthly Cost:',
            originalRequest:
                formData.original_frnrecurringcharges !== null && formData.original_frnrecurringcharges.length > 0
                    ? formatMoney(parseFloat(formData.original_frnrecurringcharges))
                    : '',
            currentRequest:
                formData.current_frnrecurringcharges !== null && formData.current_frnrecurringcharges.length > 0
                    ? formatMoney(parseFloat(formData.current_frnrecurringcharges))
                    : '',
        },
        {
            label: 'Months of Service:',
            originalRequest: formData.originalmonths,
            currentRequest: formData.currentmonths,
        },
        {
            label: 'Annual Recurring Charges:',
            originalRequest:
                formData.original_frnrecurringcharges !== null && formData.original_frnrecurringcharges.length > 0
                    ? formatMoney(parseFloat(formData.original_frnrecurringcharges))
                    : '',
            currentRequest:
                formData.current_frnrecurringcharges !== null && formData.current_frnrecurringcharges.length > 0
                    ? formatMoney(parseFloat(formData.current_frnrecurringcharges))
                    : '',
        },

        {
            label: 'One-Time Charges:',
            originalRequest:
                formData.original_frnonetimecharges !== null && formData.original_frnonetimecharges.length > 0
                    ? formatMoney(parseFloat(formData.original_frnonetimecharges))
                    : '',
            currentRequest:
                formData.current_frnonetimecharges !== null && formData.current_frnonetimecharges.length > 0
                    ? formatMoney(parseFloat(formData.current_frnonetimecharges))
                    : '',
        },
        {
            label: 'Total Requested Amount:',
            originalRequest:
                formData.original_frntotalcharges !== null && formData.original_frntotalcharges.length > 0
                    ? formatMoney(parseFloat(formData.original_frntotalcharges))
                    : '',
            currentRequest:
                formData.current_frntotalcharges !== null && formData.current_frntotalcharges.length > 0
                    ? formatMoney(parseFloat(formData.current_frntotalcharges))
                    : '',
        },
    ];

    return (
        <Box>
            <TableContainer>
                <Table sx={{ width: '46.5%', tableLayout: 'fixed', marginTop: 2 }} size='small'>
                    <TableHead>
                        <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                            <TableCell sx={{ padding: '4px 8px', width: '35%', fontSize: '13px' }}></TableCell>{' '}
                            {/* Empty header */}
                            <TableCell sx={{ padding: '4px 8px', width: '35%', fontSize: '13px' }}>
                                Original Request
                            </TableCell>
                            <TableCell sx={{ padding: '4px 8px', width: '35%', fontSize: '13px' }}>
                                Current Commitment
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {frnData.map((ea_row, index) => (
                            <TableRow key={index}>
                                <TableCell sx={{ padding: '4px 8px', fontSize: '13px' }}>{ea_row.label}</TableCell>
                                <TableCell sx={{ padding: '4px 8px', paddingLeft: '30px', fontSize: '13px' }}>
                                    {ea_row.originalRequest}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px', paddingLeft: '30px', fontSize: '13px' }}>
                                    {ea_row.currentRequest}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography variant='body2' sx={{ marginTop: 2, fontSize: '13px' }}>
                <strong>*</strong> It is possible that an authorization for an amount was approved, but no disbursement
                has yet been made for that amount. If you have questions about an amount, please contact the service
                provider on that FRN.
            </Typography>
        </Box>
    );
}
