import React from 'react';
import { useParams } from 'react-router-dom';

import ClientApplicantList from '../components/ClientConsultantApplicantList.js';
import ClientAPI from '../api/ClientAPI.js';
import UserAPI from '../api/UserAPI.js';

export default function ClientApplicantListPage() {
    const clientAPI = new ClientAPI();
    const userAPI = new UserAPI();
    let params = useParams();

    // Prop names are defined in App.js.
    return (
        <ClientApplicantList
            fundingYear={params.fundingYear}
            crn={params.crn}
            clientAPI={clientAPI}
            userAPI={userAPI}
        />
    );
}
