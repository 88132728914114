/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "ERateCentralPortalAPI",
            "endpoint": "https://ea4b8714of.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:66668ae0-5801-4fd5-87a4-02567ef1e511",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_oA5lga2BJ",
    "aws_user_pools_web_client_id": "709e30kjdloj9mi4e9uu1g0iue",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "eratecentralportal-frontend-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d3fwqaj88awu4a.cloudfront.net",
    "aws_user_files_s3_bucket": "eratecentralportals3assets105736-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
