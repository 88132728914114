import React from 'react';
import { useParams } from 'react-router-dom';
import ClientFundingYearFormDetails from '../components/ClientFundingYearFormDetails.js';
import ClientAPI from '../api/ClientAPI.js';

export default function ClientFundingYearFormDetailsPage() {
    const clientAPI = new ClientAPI();
    let params = useParams();

    // Prop names are defined in App.js.
    return (
        <ClientFundingYearFormDetails
            clientAPI={clientAPI}
            clientId={params.clientId}
            kyList={params.kyList}
            fundingYear={params.fundingYear}
            searchType={params.searchType}
        />
    );
}
