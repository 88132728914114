import React from 'react';
import { useParams } from 'react-router-dom';

import ClientFundingSummary from '../components/ClientFundingSummary.js';
import ClientAPI from '../api/ClientAPI.js';
import UserAPI from '../api/UserAPI.js';

export default function ClientFundingSummaryPage() {
    const clientAPI = new ClientAPI();
    const userAPI = new UserAPI();
    let params = useParams();

    // Prop names are defined in App.js.
    return (
        <ClientFundingSummary
            clientId={params.clientId}
            kyList={params.kyList}
            spin={params.spin}
            searchType={params.searchType}
            clientAPI={clientAPI}
            userAPI={userAPI}
        />
    );
}
