import React from 'react';
import { useNavigate } from 'react-router-dom';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import EngineeringIcon from '@mui/icons-material/Engineering';

// get_ECF_data_for_funding_analysis
export default function ClientContactSearchReport(props) {
    const clientAPI = props.clientAPI;
    const clientId = props.clientId;
    const kyList = props.kyList;
    const searchType = props.searchType;
    const [clientContactData, setClientContactData] = React.useState(null);
    const [specificClientContactObj, setSpecificClientContactObj] = React.useState(null);
    const [allClientContactObj, setAllClientContactObj] = React.useState(null);

    console.log('[ClientContactSearchReport] clientId, kyList, searchType:', clientId, kyList, searchType);

    React.useEffect(() => {
        const getClientContactData = async (kyList) => {
            const clientContactDataVar = await clientAPI.GetClientContactData(kyList);
            setClientContactData(clientContactDataVar);
            console.log(
                '[ClientContactSearchReport][useEffect][getClientContactData] clientContactDataVar:',
                clientContactDataVar
            );
            return clientContactDataVar;
        };

        // Trying to get the first available contact info
        const getClientContactDataObj = (allClientContactData) => {
            // Get all the keys of the object
            const keys = Object.keys(allClientContactData);

            if (keys.length === 0) {
                return null;
            }

            // Loop through the keys in their current order (most recent first)
            for (let i = 0; i < keys.length; i++) {
                const dataArray = allClientContactData[keys[i]];

                // If the array is not empty, return the first object
                if (dataArray && dataArray.length > 0) {
                    console.log(
                        '[ClientContactSearchReport][useEffect][getClientContactDataObj] specificClientContactDataObj:',
                        dataArray[0]
                    );
                    return dataArray[0]; // Return the first non-empty object of the get_contact_data() query. clientContactDataTemp/allClientContactData has every year with the same infornmation, we're just limiting it
                }
            }

            return null;
        };

        const getAllData = async () => {
            const clientContactDataTemp = await getClientContactData(kyList);
            if (!clientContactDataTemp) return;
            setAllClientContactObj(clientContactDataTemp);
            setSpecificClientContactObj(getClientContactDataObj(clientContactDataTemp));
        };

        getAllData();
    }, [clientAPI, kyList, searchType]);

    if (!clientContactData || !clientContactData || !specificClientContactObj) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <CircularProgress />
                <Typography variant='body1' sx={{ color: 'gray', marginTop: '10px', textAlign: 'center' }}>
                    <em>{`Gathering data for E-Rate Contact Search Results...`}</em>
                </Typography>
            </Box>
        );
    }

    return (
        <Box>
            <>
                <h4 style={{ color: '#19638B', marginTop: '25px', marginBottom: '10px' }}>
                    {`E-Rate Contact Search Results `}
                </h4>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        backgroundColor: '#f5f5f5',
                        padding: '4px 8px',
                        marginBottom: '10px',
                    }}
                >
                    <Box sx={{ flex: 1, fontSize: '13px', fontWeight: 'bold' }}>Contact Person</Box>
                    <Box sx={{ flex: 1, fontSize: '13px', fontWeight: 'bold' }}>Contact Person Information</Box>
                    <Box sx={{ flex: 1, fontSize: '13px', fontWeight: 'bold' }}>Applicant Information</Box>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '10px',
                    }}
                >
                    {/* Contact Person */}
                    <Box sx={{ flex: 1, padding: '4px 8px', fontSize: '13px' }}>
                        {specificClientContactObj.contactname}
                    </Box>

                    {/* Contact Person Information */}
                    <Box sx={{ flex: 1, padding: '4px 8px', fontSize: '13px' }}>
                        <div>{specificClientContactObj.contactaddress1}</div>
                        <div>{`${specificClientContactObj.contactcity}, ${specificClientContactObj.contactstate} ${specificClientContactObj.contactzip}`}</div>
                        <div>{`Phone: ${specificClientContactObj.contactphone}`}</div>
                        <div>
                            Email:
                            <Link
                                href={`mailto:${specificClientContactObj.contactemail}`}
                                rel='noreferrer'
                                sx={{
                                    textDecoration: 'none',
                                    fontWeight: 'normal',
                                    marginLeft: 0.5,
                                }}
                            >
                                {specificClientContactObj.contactemail}
                            </Link>
                        </div>
                    </Box>

                    {/* Applicant Information */}
                    <Box sx={{ flex: 1, padding: '4px 8px', fontSize: '13px' }}>
                        <div>{specificClientContactObj.billedentityname}</div>
                        <div>{specificClientContactObj.billedentityaddress1}</div>
                        <div>{`${specificClientContactObj.billedentitycity}, ${specificClientContactObj.billedentitystate} ${specificClientContactObj.billedentityzipcode}`}</div>

                        <div
                            style={{
                                marginTop: 10,
                            }}
                        >
                            BEN:
                            <Link
                                href={`/client-list/funding-summary/${clientId}/${kyList}/entNum`}
                                rel='noreferrer'
                                sx={{
                                    textDecoration: 'none',
                                    fontWeight: 'normal',
                                    marginLeft: 0.5,
                                }}
                            >
                                {kyList}
                            </Link>
                        </div>
                        <div>Funding Year: {specificClientContactObj.fundingyear}</div>
                    </Box>

                    <Box sx={{ gap: 1, padding: '4px 8px', fontSize: '13px' }}></Box>
                </Box>
            </>
            {Object.entries(allClientContactObj).map(([key, client], index) => (
                <p>
                    Consultant {client.fundingyear}:{' '}
                    <Link
                        href={`/client-list/consultant-report/${client.fundingyear}/${client.crn}`}
                        rel='noreferrer'
                        sx={{
                            textDecoration: 'none',
                            fontWeight: 'normal',
                            marginLeft: 1,
                            fontSize: '1.125rem',
                        }}
                    >
                        {client.billedentityname}
                    </Link>
                </p>
            ))}
            <Box sx={{ flexBasis: '700px' }}>
                <ClientContactSearchReportSection />
            </Box>
        </Box>
    );
}

function ClientContactSearchReportSection({}) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
            }}
        >
            <EngineeringIcon sx={{ fontSize: '100px', color: 'gray' }} />
            <Typography variant='h5' sx={{ marginTop: 2, color: 'gray' }}>
                Oi mate, I'm still working on this
            </Typography>
        </Box>
    );
}
