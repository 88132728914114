import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

// This is the page where the link to the ECFXXXXX FRN number is on
export default function ClientFundingHistoryDetailsECF(props) {
    const clientAPI = props.clientAPI;
    const clientId = props.clientId;
    const kyList = props.kyList;
    const program = props.program;
    const searchType = props.searchType;
    const [ecfData, setECFData] = React.useState(null);
    const [serviceProviderDisplayName, setServiceProviderDisplayName] = React.useState('');
    const [applicantName, setApplicantName] = React.useState('');

    React.useState(() => {
        const getECFData = async (searchType, kyList, state, program) => {
            const ecfdataVar = await clientAPI.GetClientECFData(searchType, kyList, state, program);
            setECFData(ecfdataVar);
            console.log('[ClientFundingHistoryDetailsECF][useEffect][getECFData] ecfdataVar:', ecfdataVar);
            return ecfdataVar;
        };

        // Trying to get the first available serviceprovidername
        const getServiceProviderName = (allECFData) => {
            // Access the last key (most recent year), first object (index 0)
            const firstObj = allECFData[0];

            // Check if serviceprovidername exists and is not null or undefined
            if (firstObj && firstObj.serviceprovidername) {
                return firstObj.serviceprovidername;
            }

            // If the first check fails, access the first key (earliest year), first object
            const lastObj = allECFData[allECFData.length - 1];

            // Check if serviceprovidername exists and is not null or undefined
            if (lastObj && lastObj.serviceprovidername) {
                return lastObj.serviceprovidername;
            }

            // Return null (or whatever we want) if a serviceprovidername couldn't be found
            return null;
        };

        const getApplicantName = (allECFData) => {
            // Access the last key (most recent year), first object (index 0)
            const firstObj = allECFData[0];

            // Check if applicantname exists and is not null or undefined
            if (firstObj && firstObj.applicantname) {
                return firstObj.applicantname;
            }

            // If the first check fails, access the first key (earliest year), first object
            const lastObj = allECFData[allECFData.length - 1];

            // Check if applicantname exists and is not null or undefined
            if (lastObj && lastObj.applicantname) {
                return lastObj.applicantname;
            }

            // Return null (or whatever we want) if a applicantname couldn't be found
            return null;
        };

        const getAllData = async () => {
            const ecfDataTemp = await getECFData(searchType, kyList, null, program);
            if (!ecfDataTemp) return;

            if (program === 'ECF' && searchType === 'spin') {
                setServiceProviderDisplayName(getServiceProviderName(ecfDataTemp));
            } else if (program === 'ECF' && (searchType === 'entNum' || searchType === 'f471')) {
                setApplicantName(getApplicantName(ecfDataTemp));
            }
        };

        getAllData();
    }, []);

    // Don't display anything until we have data.
    if (!ecfData) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <CircularProgress />
                <Typography variant='body1' sx={{ color: 'gray', marginTop: '10px', textAlign: 'center' }}>
                    <em>Gathering data for Emergency Connectivity Fund Summary Chart...</em>
                </Typography>
            </Box>
        );
    }

    return (
        <Box>
            {searchType === 'entNum' ? (
                <>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <h3 style={{ margin: 0, fontSize: '0.875rem' }}>
                            Applicant: <span style={{ fontWeight: 'normal' }}>{applicantName}</span>
                        </h3>
                    </Box>

                    <Box>
                        <h3 style={{ margin: 0, fontSize: '0.875rem' }}>
                            Billed Entity Number{kyList.includes(',') ? 's' : ''}:
                            <Link
                                href={`/client-list/funding-summary/${clientId}/${kyList}/entNum`}
                                rel='noreferrer'
                                sx={{
                                    textDecoration: 'none',
                                    fontWeight: 'normal',
                                    marginLeft: 1,
                                    // fontSize: '1.125rem',
                                }}
                            >
                                {kyList}
                            </Link>
                        </h3>
                    </Box>
                </>
            ) : searchType === 'spin' ? (
                <>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <h3 style={{ margin: 0, fontSize: '0.875rem' }}>
                            Service Provider: <span style={{ fontWeight: 'normal' }}>{serviceProviderDisplayName}</span>
                        </h3>
                    </Box>

                    <Box>
                        <h3 style={{ margin: 0, fontSize: '0.875rem' }}>
                            SPIN{kyList.includes(',') ? 's' : ''}:
                            <Link
                                href={`/client-list/funding-summary/${clientId}/${kyList}/entNum`}
                                rel='noreferrer'
                                sx={{
                                    textDecoration: 'none',
                                    fontWeight: 'normal',
                                    marginLeft: 1,
                                    // fontSize: '1.125rem',
                                }}
                            >
                                {kyList}
                            </Link>
                        </h3>
                    </Box>
                </>
            ) : (
                <>
                    <Box sx={{ display: 'flex', alignItems: 'center', fontSize: '0.875rem' }}>
                        <h3 style={{ margin: 0 }}>
                            Applicant: <span style={{ fontWeight: 'normal' }}>{applicantName}</span>
                        </h3>
                    </Box>

                    <Box>
                        <h3 style={{ margin: 0, fontSize: '0.875rem' }}>
                            Billed Entity Number{kyList.includes(',') ? 's' : ''}:
                            <Link
                                href={`/client-list/funding-summary/${clientId}/${ecfData[0]['billedentitynumber']}/entNum`}
                                rel='noreferrer'
                                sx={{
                                    textDecoration: 'none',
                                    fontWeight: 'normal',
                                    marginLeft: 1,
                                    fontSize: '1.125rem',
                                }}
                            >
                                {ecfData[0]['billedentitynumber']}
                            </Link>
                        </h3>
                    </Box>
                </>
            )}

            <Box sx={{ flexBasis: '700px' }}>
                <ClientFundingHistoryDetailsECFSection
                    clientId={clientId}
                    kyList={kyList}
                    searchType={searchType}
                    ecfData={ecfData}
                />
            </Box>
        </Box>
    );
}

function ClientFundingHistoryDetailsECFSection({ clientId, kyList, searchType, ecfData }) {
    const [ecfOrder, setECFOrder] = React.useState('asc');
    const [ecfOrderBy, setECFOrderBy] = React.useState('');

    const ecfHeaders = [
        { id: 'applicationnumber', label: '471' },
        { id: 'frn', label: 'FRN' },
        { id: 'fundingrequestnickname', label: 'FRN Nickname' },
        { id: 'serviceprovidername', label: 'Service Provider' },
        { id: 'spin', label: 'SPIN' },
        { id: 'servicetype', label: 'Service' },
        { id: 'originalrequest', label: 'Original Request' },
        { id: 'committedrequest', label: 'Current Commitment' },
        { id: 'disbursement', label: 'Disbursed' },
        { id: 'ecfUtilized', label: 'Util %' }, // If committed > 0 and disbursed > 0 = disbursed / committed. if disbursed < 0, just 0%. If committed < 0, just 0%
        { id: 'invoicingmethod', label: 'Invoice Mode' },
        { id: 'fcdldate', label: 'FCDL Date' },
        { id: 'filingwindow', label: 'ECF Window' },
    ];

    const ecfColumnStyles = {
        applicationnumber: { minWidth: '80px', maxWidth: '150px' },
        frn: { minWidth: '80px', maxWidth: '150px' },
        fundingrequestnickname: { minWidth: '150px', maxWidth: '300px' },
        billedentitynumber: { minWidth: '150px', maxWidth: '300px' },
        // serviceprovidername: { minWidth: '150px', maxWidth: '300px' },
        serviceprovidername: { minWidth: '200px', maxWidth: '200px' },
        servicetype: { minWidth: '80px', maxWidth: '150px' },
        originalrequest: { minWidth: '100px', maxWidth: '200px' },
        committedrequest: { minWidth: '100px', maxWidth: '200px' },
        disbursement: { minWidth: '100px', maxWidth: '200px' },
        ecfUtilized: { minWidth: '80px', maxWidth: '150px' },
        invoicingmethod: { minWidth: '80px', maxWidth: '150px' },
        fcdldate: { minWidth: '80px', maxWidth: '150px' },
        filingwindow: { minWidth: '80px', maxWidth: '150px' },
    };

    const f471FundingDetailsHeaders = [
        { id: 'f471applicationnumber', label: '471' },
        { id: 'frn', label: 'FRN' },
        { id: 'fundingrequestnickname', label: 'FRN Nickname' },
        { id: 'serviceprovidername', label: 'Service Provider' },
        { id: 'spin', label: 'SPIN' },
        { id: 'service', label: 'Service' },
        { id: 'originalrequest', label: 'Original Request' },
        { id: 'committedrequest', label: 'Current Commitment' },
        { id: 'disbursement', label: 'Disbursed' },
        { id: 'percentUtilized', label: 'Util.%' },
        { id: 'invoicingmode', label: 'Invoice Mode' },
        { id: 'fcdldate', label: 'FCDL Date' },
        { id: 'filingwindow', label: 'ECF Window' },
    ];

    const f471NumColumnStyles = {
        f471applicationnumber: { minWidth: '80px', maxWidth: '150px' },
        frn: { minWidth: '80px', maxWidth: '150px' },
        fundingrequestnickname: { minWidth: '80px', maxWidth: '150px' },
        spin: { minWidth: '150px', maxWidth: '300px' },
        serviceprovidername: { minWidth: '150px', maxWidth: '300px' },
        serviceType: { minWidth: '80px', maxWidth: '150px' },
        origrannualcost: { minWidth: '100px', maxWidth: '200px' },
        cmtdrannualcost: { minWidth: '100px', maxWidth: '200px' },
        origfundingrequest: { minWidth: '100px', maxWidth: '200px' },
        totalauthorizeddisbursement: { minWidth: '100px', maxWidth: '200px' },
        percentUtilized: { minWidth: '80px', maxWidth: '150px' },
        invoicingmode: { minWidth: '80px', maxWidth: '150px' },
        fcdldate: { minWidth: '80px', maxWidth: '150px' },
        filingwindow: { minWidth: '80px', maxWidth: '150px' },
    };

    const handleECFDataRequestSort = (property) => {
        const isAsc = ecfOrderBy === property && ecfOrder === 'asc';
        setECFOrder(isAsc ? 'desc' : 'asc');
        setECFOrderBy(property);
    };

    const sortECFData = (data, orderBy, order) => {
        console.log(data, ecfOrderBy, ecfOrder);

        return [...data].sort((a, b) => {
            let aValue = a[orderBy];
            let bValue = b[orderBy];

            // Convert monetary values to numbers for sorting
            if (
                orderBy.startsWith('originalrequest') ||
                orderBy.startsWith('committedrequest') ||
                orderBy.startsWith('disbursement')
            ) {
                aValue = aValue ? parseFloat(aValue.replace(/[$,]/g, '')) : 0;
                bValue = bValue ? parseFloat(bValue.replace(/[$,]/g, '')) : 0;
            }

            // Occasionally there are null fcdl dates that break sorting
            if (orderBy === 'fcdldate') {
                aValue = aValue ? new Date(aValue) : order === 'asc' ? new Date(0) : new Date(8640000000000000);
                bValue = bValue ? new Date(bValue) : order === 'asc' ? new Date(0) : new Date(8640000000000000);
            }

            // Handle sorting based on the value type
            if (aValue instanceof Date) {
                return order === 'asc' ? aValue - bValue : bValue - aValue;
            } else if (typeof aValue === 'number') {
                return order === 'asc' ? aValue - bValue : bValue - aValue;
            } else if (typeof aValue === 'string') {
                return order === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
            }

            return 0;
        });
    };

    const parseMoney = (value) => {
        if (!value || value == null) {
            return 0;
        }
        if (typeof value === 'number') {
            return value;
        }

        if (value.includes('%')) {
            return parseFloat(value.replace('%', '')) / 100;
        }
        return parseFloat(value.replace(/[$,]/g, ''));
    };

    const formatMoney = (value) => {
        // Checking if the value is 0 or "0"
        if (value === 0 || value === '0' || !value) {
            return '$0.00';
        }

        // Ensuring the value is a number
        const numericValue = parseFloat(value);

        // Formatting the number to money
        return `$${numericValue.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
    };

    const formatDate = (dateString) => {
        if (!dateString) {
            return '';
        }

        const [year, month, day] = dateString.split('-');
        return `${month}/${day}/${year}`;
    };

    const calculateUtilPercentage = (ecfData) => {
        return ecfData.map((row) => {
            const committed = parseMoney(row.committedrequest);
            const disbursed = parseMoney(row.disbursement);

            let utilization = '0%';
            if (committed > 0) {
                utilization = disbursed > 0 ? disbursed / committed + '%' : '0%';
            }

            return {
                ...row,
                ecfUtilized: utilization,
            };
        });
    };

    const calculateECFTotals = (processedData) => {
        const totals = processedData.reduce(
            (acc, rowData) => {
                acc.originalrequest += parseMoney(rowData.originalrequest);
                acc.committedrequest += parseMoney(rowData.committedrequest);
                acc.disbursement += parseMoney(rowData.disbursement);

                return acc;
            },
            {
                originalrequest: 0,
                committedrequest: 0,
                disbursement: 0,
            }
        );

        totals.ecfUtilized =
            totals.committedrequest > 0
                ? ((totals.disbursement / totals.committedrequest) * 100).toFixed(2) + '%'
                : '0%';

        return totals;
    };

    const convertInvoicingMode = (invoice) => {
        if (invoice) {
            if (invoice === 'Applicant - FCC Form 472 (BEAR Form)') {
                return 'BEAR';
            } else if (invoice === 'Service Provider - FCC Form 474 (SPI Form)') {
                return 'SPI';
            } else {
                return 'NOT SET';
            }
        } else {
            return 'NOT SET';
        }
    };

    const ecfDataWithUtil = calculateUtilPercentage(ecfData);
    const sortedECFData = sortECFData(ecfDataWithUtil, ecfOrderBy, ecfOrder);
    const ecfTotals = calculateECFTotals(ecfDataWithUtil);
    return (
        <Box>
            <h4 style={{ color: '#19638B', marginTop: '25px', marginBottom: '10px' }}>
                Emergency Connectivity Fund Summary Chart
            </h4>

            {searchType === 'entNum' ? (
                <TableContainer component={Paper}>
                    <Table
                        sx={{
                            minWidth: 800,
                            width: '100%', // Ensures the table takes up the full width of the container
                            tableLayout: 'fixed', // Helps in managing how the table handles the available width
                        }}
                        size='small'
                    >
                        <TableHead>
                            <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                                {ecfHeaders.map((header) => (
                                    <TableCell
                                        key={header.id}
                                        sortDirection={ecfOrderBy === header.id ? ecfOrder : false}
                                        sx={{
                                            padding: '4px 8px',
                                            lineHeight: '1.2',
                                            flexBasis: 'auto', // Allows the columns to automatically adjust their width
                                            flexGrow: 1, // Allows the columns to grow to fill available space
                                            flexShrink: 1, // Allows the columns to shrink if necessary
                                            fontSize: '10px',
                                            ...ecfColumnStyles[header.id],
                                        }}
                                    >
                                        <TableSortLabel
                                            active={ecfOrderBy === header.id}
                                            direction={ecfOrderBy === header.id ? ecfOrder : 'asc'}
                                            onClick={() => handleECFDataRequestSort(header.id)}
                                        >
                                            {header.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {sortedECFData.map((rowData, index) => (
                                <TableRow
                                    key={`${rowData.year}-${index}`}
                                    sx={{
                                        backgroundColor: index % 2 === 0 ? 'white' : '#f5f5f5',
                                    }}
                                >
                                    {/* path='/client-list/funding-history-details/:clientId/:kyList/:program/:searchType' */}
                                    <TableCell sx={{ padding: '4px 8px' }}>
                                        <Link
                                            // href={`/client-list/funding-year-details/${clientId}/${kyList}/${fundingYear}/${rowData.f471applicationnumber}/${rowData.applicantstate}/f471/`}
                                            href={`/client-list/funding-history-details/${clientId}/${rowData.applicationnumber}/ECF/f471`} // state = all, program = ECF, type = f471
                                            rel='noreferrer'
                                            sx={{ textDecoration: 'none', fontSize: '10px' }}
                                        >
                                            {rowData.applicationnumber}
                                        </Link>
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px' }}>
                                        <Link
                                            href={`/client-list/form-details-all-ecf/${clientId}/${rowData.frn}/frn`}
                                            rel='noreferrer'
                                            sx={{ textDecoration: 'none', fontSize: '10px' }}
                                        >
                                            {rowData.frn}
                                        </Link>
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {rowData.fundingrequestnickname}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {rowData.serviceprovidername}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px' }}>
                                        <Link
                                            href={`/client-list/funding-summary/${clientId}/${kyList}/${rowData.spin}/spin`}
                                            rel='noreferrer'
                                            sx={{ textDecoration: 'none', fontSize: '10px' }}
                                        >
                                            {rowData.spin}
                                        </Link>
                                    </TableCell>
                                    {/* <TableCell
                                        align='center'
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            height: '100%',
                                            overflow: 'visible',
                                            padding: '4px 8px',
                                        }}
                                    >
                                        <div
                                            style={{
                                                whiteSpace: 'normal',
                                                overflow: 'visible',
                                                transform: 'scale(0.8)',
                                                transformOrigin: 'left center',
                                            }}
                                        >
                                            {rowData.serviceprovidername}
                                        </div>
                                    </TableCell> */}
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {rowData.servicetype}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {formatMoney(rowData.originalrequest)}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {/* {formatMoney(rowData.committedrequest)} */}
                                        {formatDate(rowData.fcdldate) !== '' && rowData.committedrequest <= 0
                                            ? formatMoney(rowData.committedrequest) + ' (NF)'
                                            : formatMoney(rowData.committedrequest)}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {formatMoney(rowData.disbursement)}
                                    </TableCell>

                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {parseInt(rowData.committedrequest) > 0
                                            ? parseInt(rowData.disbursement) > 0
                                                ? (
                                                      (parseInt(rowData.disbursement) /
                                                          parseInt(rowData.committedrequest)) *
                                                      100
                                                  ).toFixed(2) + '%'
                                                : '0%'
                                            : '0%'}
                                    </TableCell>

                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {convertInvoicingMode(rowData.invoicingmethod)}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {formatDate(rowData.fcdldate)}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {rowData.filingwindow}
                                    </TableCell>
                                </TableRow>
                            ))}

                            <TableRow sx={{ backgroundColor: ecfData.length % 2 === 0 ? 'white' : '#f5f5f5' }}>
                                <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>Totals</TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                    {formatMoney(ecfTotals.originalrequest)}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                    {formatMoney(ecfTotals.committedrequest)}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                    {formatMoney(ecfTotals.disbursement)}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                    {ecfTotals.ecfUtilized}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <TableContainer component={Paper}>
                    <Table
                        sx={{
                            minWidth: 800,
                            width: '100%', // Ensures the table takes up the full width of the container
                            tableLayout: 'fixed', // Helps in managing how the table handles the available width
                        }}
                        size='small'
                    >
                        <TableHead>
                            <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                                {f471FundingDetailsHeaders.map((header) => (
                                    <TableCell
                                        key={header.id}
                                        sortDirection={ecfOrderBy === header.id ? ecfOrder : false}
                                        sx={{
                                            padding: '4px 8px',
                                            lineHeight: '1.2',
                                            flexBasis: 'auto', // Allows the columns to automatically adjust their width
                                            flexGrow: 1, // Allows the columns to grow to fill available space
                                            flexShrink: 1, // Allows the columns to shrink if necessary
                                            fontSize: '10px',
                                            ...f471NumColumnStyles[header.id],
                                        }}
                                    >
                                        <TableSortLabel
                                            active={ecfOrderBy === header.id}
                                            direction={ecfOrderBy === header.id ? ecfOrder : 'asc'}
                                            onClick={() => handleECFDataRequestSort(header.id)}
                                        >
                                            {header.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedECFData.map((rowData, index) => (
                                <TableRow
                                    key={`${rowData.filingwindow}-${index}`}
                                    sx={{
                                        backgroundColor: index % 2 === 0 ? 'white' : '#f5f5f5',
                                    }}
                                >
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        <Link
                                            // href={`/client-list/funding-year-details/${clientId}/${kyList}/${fundingYear}/${rowData.f471applicationnumber}/${rowData.applicantstate}/f471/`}
                                            // href={`/client-list/funding-year-details/${clientId}/${kyList}/${rowData.applicationnumber}/all/ECF/f471/`} // state = all, program = ECF, type = f471
                                            href={`/client-list/funding-history-details/${clientId}/${rowData.applicationnumber}/ECF/f471`} // state = all, program = ECF, type = f471
                                            rel='noreferrer'
                                            sx={{ textDecoration: 'none' }}
                                        >
                                            {rowData.applicationnumber}
                                        </Link>
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        <Link
                                            // href={`/client-list/form-details/${clientId}/${
                                            //     rowData.frn
                                            // }/${rowData.fundingyear}/${
                                            //     searchType === 'entNum' || searchType === 'f471' ? 'frn' : 'entNum'
                                            // }`}
                                            href={`/client-list/form-details-all-ecf/${clientId}/${rowData.frn}/frn`}
                                            rel='noreferrer'
                                            sx={{ textDecoration: 'none' }}
                                        >
                                            {rowData.frn}
                                        </Link>
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {rowData.fundingrequestnickname}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {rowData.serviceprovidername}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        <Link
                                            href={`/client-list/funding-summary/${clientId}/${kyList}/${rowData.spin}/spin`}
                                            rel='noreferrer'
                                            sx={{ textDecoration: 'none' }}
                                        >
                                            {rowData.spin}
                                        </Link>
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {rowData.servicetype}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {formatMoney(rowData.originalrequest)}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {/* {formatMoney(rowData.committedrequest)} */}
                                        {formatDate(rowData.fcdldate) !== '' && rowData.committedrequest <= 0
                                            ? formatMoney(rowData.committedrequest) + ' (NF)'
                                            : formatMoney(rowData.committedrequest)}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {formatMoney(rowData.disbursement)}
                                    </TableCell>

                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {parseInt(rowData.committedrequest) > 0
                                            ? parseInt(rowData.disbursement) > 0
                                                ? (
                                                      (parseInt(rowData.disbursement) /
                                                          parseInt(rowData.committedrequest)) *
                                                      100
                                                  ).toFixed(2) + '%'
                                                : '0%'
                                            : '0%'}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {convertInvoicingMode(rowData.invoicingmethod)}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {formatDate(rowData.fcdldate)}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {rowData.filingwindow}
                                    </TableCell>
                                </TableRow>
                            ))}

                            {sortedECFData.length > 0 && (
                                <TableRow
                                    sx={{ backgroundColor: sortedECFData.length % 2 === 0 ? 'white' : '#f5f5f5' }}
                                >
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>Totals</TableCell>
                                    <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                    <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                    <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                    <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                    <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {formatMoney(ecfTotals.originalrequest)}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {formatMoney(ecfTotals.committedrequest)}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {formatMoney(ecfTotals.disbursement)}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '10px' }}>
                                        {ecfTotals.ecfUtilized}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                    <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                    <TableCell sx={{ padding: '4px 8px' }}></TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Box>
    );
}
