import React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import TableSortLabel from '@mui/material/TableSortLabel';
import logo from '../media/ERateCentralLogo.png';

const CACHE_INCREMENT = 1;
const CLIENT_CACHE_NAME = 'ced-client-form-details-' + CACHE_INCREMENT;

// The working FRN link's page
export default function ClientFundingYearFormDetails(props) {
    const clientAPI = props.clientAPI;
    const clientId = props.clientId;
    const kyList = props.kyList;
    const fundingYear = props.fundingYear;
    const searchType = props.searchType;
    const formDataKey = `/ClientList/form-details/${clientId}/${kyList}/${fundingYear}/${searchType}`;
    const [formData, setFormData] = React.useState(null);

    React.useEffect(() => {
        const cacheData = async (key, data) => {
            try {
                if (data) {
                    let APICache = await caches.open(CLIENT_CACHE_NAME);
                    let headersOptions = { headers: { 'Content-Type': 'application/json' } };
                    await APICache.put(key, new Response(JSON.stringify(data), headersOptions));
                    console.log(
                        `5) [ClientFundingYearFormDetails][useEffect][cacheData] Data cached under key: ${key}`
                    );
                }
            } catch (error) {
                console.error(
                    `5) [ClientFundingYearFormDetails][useEffect][cacheData] Error caching data under key: ${key}`,
                    error
                );
            }
        };

        const checkCacheForPreviousData = async (formKey) => {
            let APICache = await caches.open(CLIENT_CACHE_NAME);

            try {
                // Check for cached form data
                const formResponse = await APICache.match(formKey);
                console.log(
                    '1) [ClientFundingYearFormDetails][useEffect][checkCacheForPreviousData] formResponse:',
                    formResponse
                );

                // If data was found, set it to state to temporarily display (old) data to the user
                if (formResponse !== undefined) {
                    const cachedFormData = await formResponse.json();
                    setFormData(cachedFormData);
                    console.log(
                        '2) [ClientFundingYearFormDetails][useEffect][checkCacheForPreviousData] cachedFormData (cache found):',
                        cachedFormData
                    );
                    return true;
                }
            } catch (error) {
                console.error(
                    `[ClientFundingYearFormDetails][useEffect][checkCacheForPreviousData] Error checking cache for key: ${formKey}`,
                    error
                );
            }
            // Return false if no form data was found
            console.log(
                '2) [ClientFundingYearFormDetails][useEffect][checkCacheForPreviousData] cachedFormData (cache not found):'
            );
            return false;
        };

        const getFormData = async (type, ky, fy) => {
            try {
                const formDataVar = await clientAPI.GetClientFundingYearFormDetailsData(type, ky, fy);
                if (formDataVar) {
                    setFormData(formDataVar[0]);
                    console.log('4) [ClientFundingYearFormDetails][useEffect][getFormData] formDataVar:', formDataVar);
                    return formDataVar[0];
                }
            } catch (error) {
                console.error(
                    '4) [ClientFundingYearFormDetails][useEffect][getFormData] Error fetching form data:',
                    error
                );
            }
            return null;
        };

        const fetchAndCacheData = async () => {
            const formDataTemp = await getFormData(searchType, kyList, fundingYear);
            if (formDataTemp) await cacheData(formDataKey, formDataTemp);
        };

        const triggerBackgroundRefresh = async () => {
            try {
                await fetchAndCacheData();
                console.log(
                    '[ClientFundingYearFormDetails][useEffect][triggerBackgroundRefresh] Background refresh complete.'
                );
            } catch (error) {
                console.error(
                    '[ClientFundingYearFormDetails][useEffect][triggerBackgroundRefresh] Error refreshing data in the background:',
                    error
                );
            }
        };

        const fetchAllFormData = async () => {
            try {
                const didWeUseCachedData = await checkCacheForPreviousData(formDataKey);
                console.log(
                    '3) [ClientFundingYearFormDetails][useEffect][fetchAllFormData] didWeUseCachedData:',
                    didWeUseCachedData
                );

                if (!didWeUseCachedData) await fetchAndCacheData();
                if (didWeUseCachedData) await triggerBackgroundRefresh();
            } catch (error) {
                console.error(
                    '[ClientFundingYearFormDetails][useEffect][fetchAllFormData] Error fetching funding data:',
                    error
                );
            }
        };

        fetchAllFormData();
    }, [clientAPI, formDataKey, fundingYear, kyList, searchType]);

    // Don't display anything until we have data.
    if (!formData) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <CircularProgress />
                <Typography variant='body1' sx={{ color: 'gray', marginTop: '10px', textAlign: 'center' }}>
                    <em>{`Gathering data for E-Rate FRN Detail...`}</em>
                </Typography>
            </Box>
        );
    }

    const parseMoney = (value) => {
        if (!value || value == null) {
            return 0;
        }
        if (typeof value === 'number') {
            return value;
        }

        if (value.includes('%')) {
            return parseFloat(value.replace('%', '')) / 100;
        }
        return parseFloat(value.replace(/[$,]/g, ''));
    };

    const formatMoney = (value) => `$${value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;

    const formatDate = (dateString) => {
        if (!dateString) {
            return '';
        }

        const [year, month, day] = dateString.split('-');
        return `${month}/${day}/${year}`;
    };

    return (
        <Box>
            <Link href='https://e-ratecentral.com' target='_blank' rel='noreferrer'>
                <img height='60px' src={logo} alt='Logo' style={{ cursor: 'pointer' }} />
            </Link>

            <Box sx={{ display: 'flex', alignItems: 'center', fontSize: '13px' }}>
                <h2>E-Rate FRN Detail</h2>
            </Box>

            <Box>
                <TableContainer>
                    <Table sx={{ width: '46.5%', tableLayout: 'fixed' }} size='small'>
                        <TableHead>
                            <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                                <TableCell sx={{ padding: '4px 8px', fontSize: '13px' }}>
                                    FRN: <span style={{ fontWeight: 'normal' }}>{formData.frn}</span>
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px', fontSize: '13px' }}>
                                    FRN Nickname: <span style={{ fontWeight: 'normal' }}>{formData.frnnickname}</span>
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px', fontSize: '13px' }}>
                                    Funding Year:
                                    <Link
                                        href={`/client-list/funding-year-details/${clientId}/${formData.ben}/${formData.fundingyear}/entNum`}
                                        rel='noreferrer'
                                        sx={{
                                            textDecoration: 'none',
                                            fontWeight: 'normal',
                                            marginLeft: 0.5,
                                        }}
                                    >
                                        {formData.fundingyear}
                                    </Link>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                </TableContainer>
            </Box>
            {/* <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    backgroundColor: '#f5f5f5',
                    padding: '4px 8px',
                    marginBottom: '10px',
                    maxWidth: '46.5%',
                    justifyContent: 'space-between', // Ensure equal spacing
                }}
            >
                <Box sx={{ flex: 1, fontSize: '13px', fontWeight: 'bold', marginRight: '8px' }}>
                    FRN: <span style={{ fontWeight: 'normal' }}>{formData.frn}</span>
                </Box>
                <Box sx={{ flex: 1, fontSize: '13px', fontWeight: 'bold', marginRight: '8px' }}>
                    FRN Nickname: <span style={{ fontWeight: 'normal' }}>{formData.frnnickname}</span>
                </Box>
                <Box sx={{ flex: 1, fontSize: '13px', fontWeight: 'bold' }}>
                    Funding Year:
                    <Link
                        href={`/client-list/funding-year-details/${clientId}/${formData.ben}/${formData.fundingyear}/entNum`}
                        rel='noreferrer'
                        sx={{
                            textDecoration: 'none',
                            fontWeight: 'normal',
                            marginLeft: 0.5,
                        }}
                    >
                        {formData.fundingyear}
                    </Link>
                </Box>
            </Box> */}

            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, marginTop: 2, fontSize: '13px' }}>
                <Box id='client-info' sx={{ flex: 2.5 }}>
                    <h4 style={{ margin: 0 }}>
                        Applicant:
                        <Link
                            href={`/client-list/contact-search-report/${clientId}/${formData.ben}/${searchType}`}
                            rel='noreferrer'
                            sx={{
                                textDecoration: 'none',
                                fontWeight: 'normal',
                                marginLeft: 1,
                            }}
                        >
                            {formData.billedentityname}
                        </Link>
                    </h4>
                    <h4 style={{ margin: 0 }}>
                        Billed Entity Number:
                        <Link
                            href={`/client-list/funding-summary/${clientId}/${formData.ben}/entNum`}
                            rel='noreferrer'
                            sx={{ textDecoration: 'none', fontWeight: 'normal', marginLeft: 1 }}
                        >
                            {formData.ben}
                        </Link>
                    </h4>
                    <h4 style={{ margin: 0 }}>
                        Type: <span style={{ fontWeight: 'normal', marginLeft: 1 }}>{formData.applicanttype}</span>
                    </h4>
                </Box>
            </Box>

            <Box id='f470-f471-info' sx={{ marginTop: 2, fontSize: '13px', maxWidth: '46.5%' }}>
                <h4 style={{ margin: 0 }}>
                    470#:
                    <Link
                        href={`/f470/f470-applications/details/${formData.establishingfccform470number}/1`}
                        target='_blank'
                        rel='noreferrer'
                        sx={{ textDecoration: 'none', fontWeight: 'normal', marginLeft: 1 }}
                    >
                        {formData.establishingfccform470number}
                    </Link>
                </h4>

                <h4 style={{ margin: 0 }}>
                    471#:
                    <Link
                        href={`/client-list/funding-year-details/${clientId}/${kyList}/${fundingYear}/${formData.f471applicationnumber}/${formData.applicantstate}/f471`}
                        rel='noreferrer'
                        sx={{ textDecoration: 'none', fontWeight: 'normal', marginLeft: 1 }}
                    >
                        {formData.f471applicationnumber}
                    </Link>
                </h4>

                <h4 style={{ margin: 0 }}>
                    SPIN:
                    <Link
                        href={`/client-list/funding-summary/${clientId}/${kyList}/${formData.spin}/spin`}
                        // target='_blank'
                        rel='noreferrer'
                        sx={{ textDecoration: 'none', fontWeight: 'normal', marginLeft: 1 }}
                    >
                        {formData.spin}
                    </Link>
                </h4>

                <h4 style={{ margin: 0 }}>
                    Service Type:{' '}
                    <span style={{ fontWeight: 'normal', marginLeft: 1 }}>
                        {formData.cmtdfrnservicetype === '' || formData.cmtdfrnservicetype === null
                            ? formData.origfrnservicetype
                            : formData.cmtdfrnservicetype}
                    </span>
                </h4>

                <h4 style={{ margin: 0 }}>
                    Wave: <span style={{ fontWeight: 'normal', marginLeft: 1 }}>{formData.wavenumber}</span>
                </h4>

                <h4 style={{ margin: 0 }}>
                    FCDL Date:{' '}
                    <span style={{ fontWeight: 'normal', marginLeft: 1 }}>{formatDate(formData.fcdldate)}</span>
                </h4>

                <h4 style={{ margin: 0 }}>
                    Status: <span style={{ fontWeight: 'normal', marginLeft: 1 }}>{formData.frnstatus}</span>
                </h4>

                <h4 style={{ margin: 0 }}>
                    Status Memo:
                    <span
                        style={{
                            fontWeight: 'normal',
                            display: 'block',
                            wordWrap: 'break-word',
                            whiteSpace: 'normal',
                            maxWidth: '100%', // 100% of the parent element (46.5%)
                        }}
                    >
                        {formData.fcdlcommentfor471application || formData.fcdlcommentforfrn ? (
                            <ul
                                style={{
                                    listStyleType: 'none',
                                    paddingLeft: '50px',
                                    margin: '5px 0 0',
                                    wordWrap: 'break-word', // Allow breaking within words if necessary
                                    whiteSpace: 'normal', // Allow text to wrap to the next line
                                }}
                            >
                                {formData.fcdlcommentfor471application && (
                                    <li style={{ textIndent: '-10px', marginBottom: '4px' }}>
                                        <span style={{ textIndent: '10px' }}>
                                            - {formData.fcdlcommentfor471application}
                                        </span>
                                    </li>
                                )}
                                {formData.fcdlcommentforfrn && (
                                    <li style={{ textIndent: '-10px' }}>
                                        <span style={{ textIndent: '10px' }}>- {formData.fcdlcommentforfrn}</span>
                                    </li>
                                )}
                            </ul>
                        ) : (
                            <span>No comments available.</span>
                        )}
                    </span>
                </h4>
            </Box>

            <Box sx={{ display: 'flex', marginTop: 2, fontSize: '13px' }}>
                <Box id='service-info' sx={{ flex: 1, marginRight: '8px' }}>
                    <h4 style={{ margin: 0 }}>
                        Service Start Date (471):{' '}
                        <span style={{ fontWeight: 'normal', marginLeft: 1 }}>
                            {formData.cmtd471ssd !== '' && formData.cmtd471ssd !== null
                                ? formatDate(formData.cmtd471ssd)
                                : formData.orig471ssd !== null
                                ? formatDate(formData.orig471ssd)
                                : ''}
                        </span>
                    </h4>

                    <h4 style={{ margin: 0 }}>
                        Current Commitment:{' '}
                        <span style={{ fontWeight: 'normal', marginLeft: 1 }}>{formData.cmtdfundingrequest}</span>
                    </h4>

                    <h4 style={{ margin: 0 }}>
                        Disbursed Amount*:{' '}
                        <span style={{ fontWeight: 'normal', marginLeft: 1 }}>
                            {formData.totalauthorizeddisbursement}
                        </span>
                    </h4>

                    <h4 style={{ margin: 0 }}>
                        Last Date of Service:{' '}
                        <span style={{ fontWeight: 'normal', marginLeft: 1 }}>
                            {formatDate(formData.contractexpirationdate)}
                        </span>
                    </h4>
                </Box>

                <Box id='service-info-2' sx={{ flex: 2.5 }}>
                    <h4 style={{ margin: 0 }}>
                        Service Start Date (486):{' '}
                        <span style={{ fontWeight: 'normal', marginLeft: 1 }}>
                            {formatDate(formData.f486servicestartdate)}
                        </span>
                    </h4>

                    <h4 style={{ margin: 0 }}>
                        Payment Mode:{' '}
                        <span style={{ fontWeight: 'normal', marginLeft: 1 }}>{formData.invoicingmode}</span>
                    </h4>

                    <h4 style={{ margin: 0 }}>
                        Undisbursed:{' '}
                        <span style={{ fontWeight: 'normal', marginLeft: 1 }}>
                            {formData.cmtdfundingrequest === ''
                                ? ''
                                : formData.totalauthorizeddisbursement === null ||
                                  parseMoney(formData.totalauthorizeddisbursement) < 0
                                ? formData.cmtdfundingrequest
                                : formatMoney(
                                      parseMoney(formData.cmtdfundingrequest) -
                                          parseMoney(formData.totalauthorizeddisbursement)
                                  )}
                        </span>
                    </h4>

                    <h4 style={{ margin: 0 }}>
                        Last Date to Invoice:{' '}
                        <span style={{ fontWeight: 'normal', marginLeft: 1 }}>
                            {formatDate(formData.lastdatetoinvoice)}
                        </span>
                    </h4>

                    <h4 style={{ margin: 0 }}>
                        Service Delivery Deadline:{' '}
                        <span style={{ fontWeight: 'normal', marginLeft: 1 }}>
                            {formatDate(formData.servicedeliverydeadline)}
                        </span>
                    </h4>
                </Box>
            </Box>

            <Box sx={{ marginTop: 2 }}>
                <ClientFundingYearFormDetailsSection formData={formData} />
            </Box>
        </Box>
    );
}

function ClientFundingYearFormDetailsSection({ formData }) {
    const [sortColumn, setSortColumn] = React.useState('pcWave');
    const [sortOrder, setSortOrder] = React.useState('desc');

    if (!formData) {
        return null;
    }

    const isTableEmpty = (formData) => {
        // Check if formData is an object and not null
        if (!formData || typeof formData !== 'object') {
            return true;
        }

        const relevantFields = [
            formData.pcwavenumber,
            formData.revisedfcdldate,
            formData.postcommitmentrationale,
            formData.rfcdlcomment,
        ];

        // Return true if all relevant fields are empty (null, empty string, or undefined)
        return relevantFields.every((value) => value == null || value.trim() === '');
    };

    const handleSortRequest = (property) => {
        const isAsc = sortColumn === property && sortOrder === 'asc';
        setSortOrder(isAsc ? 'desc' : 'asc');
        setSortColumn(property);
    };

    const sortedData = () => {
        // Split the data into arrays
        const pcWaveNumbers = formData.pcwavenumber ? formData.pcwavenumber.split('|||') : [];
        const revisedDates = formData.revisedfcdldate ? formData.revisedfcdldate.split('|||') : [];
        const rationales = formData.postcommitmentrationale ? formData.postcommitmentrationale.split('|||') : [];
        const rfcdlComments = formData.rfcdlcomment ? formData.rfcdlcomment.split('|||') : [];

        // Combine data into an array of objects
        const combinedData = pcWaveNumbers.map((pcWave, index) => ({
            pcWave,
            rfcdlDate: revisedDates[index] || '',
            rationale: rationales[index] || '',
            rfcdlComment: rfcdlComments[index] || '',
        }));

        return combinedData.sort((a, b) => {
            let aValue = a[sortColumn];
            let bValue = b[sortColumn];

            // Convert to Date if we're sorting by 'rfcdlDate'
            if (sortColumn === 'rfcdlDate') {
                aValue = new Date(aValue);
                bValue = new Date(bValue);
            }

            if (sortOrder === 'asc') {
                return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
            } else {
                return aValue > bValue ? -1 : aValue < bValue ? 1 : 0;
            }
        });
    };

    const frnData = [
        {
            label: 'Total Monthly Cost:',
            originalRequest:
                formData.origrmonthlycost !== null && formData.origrmonthlycost.length > 0
                    ? formData.origrmonthlycost
                    : '',
            currentRequest:
                formData.cmtdrmonthlycost !== null && formData.cmtdrmonthlycost.length > 0
                    ? formData.cmtdrmonthlycost
                    : '',
        },
        {
            label: 'Total Ineligible Monthly Cost:',
            originalRequest:
                formData.origrineligiblecost !== null && formData.origrineligiblecost.length > 0
                    ? formData.origrineligiblecost
                    : '',
            currentRequest:
                formData.cmtdrineligiblecost !== null && formData.cmtdrineligiblecost.length > 0
                    ? formData.cmtdrineligiblecost
                    : '',
        },
        {
            label: 'Months of Service:',
            originalRequest: formData.origrmonthsofservice,
            currentRequest: formData.cmtdrmonthsofservice,
        },
        {
            label: 'Annual Recurring Charges:',
            originalRequest:
                formData.origrannualcost !== null && formData.origrannualcost.length > 0
                    ? formData.origrannualcost
                    : '',
            currentRequest:
                formData.cmtdrannualcost !== null && formData.cmtdrannualcost.length > 0
                    ? formData.cmtdrannualcost
                    : '',
        },

        {
            label: 'Estimated One Time Cost:',
            originalRequest:
                formData.orignreligiblecost !== null && formData.orignreligiblecost.length > 0
                    ? formData.orignreligiblecost
                    : '',
            currentRequest:
                formData.cmtdnreligiblecost !== null && formData.cmtdnreligiblecost.length > 0
                    ? formData.cmtdnreligiblecost
                    : '',
        },
        {
            label: 'Total One Time Ineligible Cost:',
            originalRequest:
                formData.orignrineligiblecost !== null && formData.orignrineligiblecost.length > 0
                    ? formData.orignrineligiblecost
                    : '',
            currentRequest:
                formData.cmtdnrineligiblecost !== null && formData.cmtdnrineligiblecost.length > 0
                    ? formData.cmtdnrineligiblecost
                    : '',
        },
        {
            label: 'Total One Time Cost:',
            originalRequest: formData.orignrcost !== null && formData.orignrcost.length > 0 ? formData.orignrcost : '',
            currentRequest: formData.cmtdnrcost !== null && formData.cmtdnrcost.length > 0 ? formData.cmtdnrcost : '',
        },
        {
            label: 'Estimated Monthly Cost:',
            originalRequest:
                formData.origreligiblecost !== null && formData.origreligiblecost.length > 0
                    ? formData.origreligiblecost
                    : '',
            currentRequest:
                formData.cmtdreligiblecost !== null && formData.cmtdreligiblecost.length > 0
                    ? formData.cmtdreligiblecost
                    : '',
        },
        {
            label: 'Estimated Annual Cost:',
            originalRequest:
                formData.origtotalcost !== null && formData.origtotalcost.length > 0 ? formData.origtotalcost : '',
            currentRequest:
                formData.cmtdtotalcost !== null && formData.cmtdtotalcost.length > 0 ? formData.cmtdtotalcost : '',
        },
        {
            label: 'Discount Percent:',
            originalRequest:
                formData.origdiscount !== null && formData.origdiscount.length > 0
                    ? `${parseInt(formData.origdiscount).toFixed(2)}%`
                    : '',
            currentRequest:
                formData.cmtddiscount !== null && formData.cmtddiscount.length > 0
                    ? `${parseInt(formData.cmtddiscount).toFixed(2)}%`
                    : '',
        },
        {
            label: 'Requested Amount:',
            originalRequest:
                formData.origfundingrequest !== null && formData.origfundingrequest.length > 0
                    ? formData.origfundingrequest
                    : '',
            currentRequest:
                formData.cmtdfundingrequest !== null && formData.cmtdfundingrequest.length > 0
                    ? formData.cmtdfundingrequest
                    : '',
        },
    ];

    return (
        <Box>
            <TableContainer>
                <Table sx={{ width: '46.5%', tableLayout: 'fixed', marginTop: 2 }} size='small'>
                    <TableHead>
                        <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                            <TableCell sx={{ width: '35%' }}></TableCell> {/* Empty header */}
                            <TableCell sx={{ width: '35%', fontSize: '13px' }}>Original Request</TableCell>
                            <TableCell sx={{ width: '35%', fontSize: '13px' }}>Current Request</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {frnData.map((ea_row, index) => (
                            <TableRow key={index}>
                                <TableCell sx={{ padding: '4px 8px', fontSize: '13px' }}>{ea_row.label}</TableCell>
                                <TableCell sx={{ padding: '4px 8px', paddingLeft: '30px', fontSize: '13px' }}>
                                    {ea_row.originalRequest}
                                </TableCell>
                                <TableCell sx={{ padding: '4px 8px', paddingLeft: '30px', fontSize: '13px' }}>
                                    {ea_row.currentRequest}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {!isTableEmpty(formData) && (
                <TableContainer>
                    <Table sx={{ width: '100%', tableLayout: 'fixed', marginTop: 5 }} size='small'>
                        <TableHead>
                            <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                                <TableCell
                                    sx={{ width: '10%', padding: '4px 8px', fontSize: '13px' }}
                                    sortDirection={sortColumn === 'pcWave' ? sortOrder : false}
                                >
                                    <TableSortLabel
                                        active={sortColumn === 'pcWave'}
                                        direction={sortColumn === 'pcWave' ? sortOrder : 'asc'}
                                        onClick={() => handleSortRequest('pcWave')}
                                    >
                                        PC Wave
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    sx={{ width: '10%', padding: '4px 8px', fontSize: '13px' }}
                                    sortDirection={sortColumn === 'rfcdlDate' ? sortOrder : false}
                                >
                                    <TableSortLabel
                                        active={sortColumn === 'rfcdlDate'}
                                        direction={sortColumn === 'rfcdlDate' ? sortOrder : 'asc'}
                                        onClick={() => handleSortRequest('rfcdlDate')}
                                    >
                                        RFCDL Date
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    sx={{ padding: '4px 8px', fontSize: '13px' }}
                                    sortDirection={sortColumn === 'rationale' ? sortOrder : false}
                                >
                                    <TableSortLabel
                                        active={sortColumn === 'rationale'}
                                        direction={sortColumn === 'rationale' ? sortOrder : 'asc'}
                                        onClick={() => handleSortRequest('rationale')}
                                    >
                                        Post-Commitment Rationale
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    sx={{ padding: '4px 8px', fontSize: '13px' }}
                                    sortDirection={sortColumn === 'rfcdlComment' ? sortOrder : false}
                                >
                                    <TableSortLabel
                                        active={sortColumn === 'rfcdlComment'}
                                        direction={sortColumn === 'rfcdlComment' ? sortOrder : 'asc'}
                                        onClick={() => handleSortRequest('rfcdlComment')}
                                    >
                                        RFCDL Comment
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedData().map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell sx={{ width: '10%', padding: '4px 8px', fontSize: '13px' }}>
                                        {row.pcWave}
                                    </TableCell>
                                    <TableCell sx={{ width: '10%', padding: '4px 8px', fontSize: '13px' }}>
                                        {row.rfcdlDate}
                                    </TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '13px' }}>{row.rationale}</TableCell>
                                    <TableCell sx={{ padding: '4px 8px', fontSize: '13px' }}>
                                        {row.rfcdlComment}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            <Typography variant='body2' sx={{ marginTop: 2, fontSize: '13px' }}>
                <strong>*</strong> It is possible that an authorization for an amount was approved, but no disbursement
                has yet been made for that amount. If you have questions about an amount, please contact the service
                provider on that FRN.
            </Typography>
        </Box>
    );
}
