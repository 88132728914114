import React from 'react';
import { Box, Button } from '@mui/material';
import Link from '@mui/material/Link';
import UserAPI from '../api/UserAPI.js';
import { useAuthContext } from '../useAuthContext';
import { useNavigate } from 'react-router-dom';

const userAPI = new UserAPI();

function SystemSettings() {
    const auth = useAuthContext();
    const navigate = useNavigate();
    const [isAdmin, setIsAdmin] = React.useState(false);

    const BUILD_ENV = process.env.REACT_APP_BUILD_ENV || 'unknown';

    React.useEffect(() => {
        const getAdminStatus = async () => {
            try {
                let adminResult = await userAPI.IsAdmin(auth.cognitoID);
                setIsAdmin(adminResult);

                if (!adminResult) {
                    navigate('/');
                }
            } catch (error) {
                navigate('/');
            }
        };

        getAdminStatus();
    }, []);

    //console.log('process.env = ', process.env);

    if (isAdmin) {
        return (
            <>
                <h2>System Settings</h2>
                <Box>
                    <Button
                        variant='contained'
                        color='secondary'
                        size='small'
                        href='/client-list/services'
                        sx={{ marginRight: '16px' }}
                    >
                        Manage Services List
                    </Button>
                    <Button variant='contained' color='secondary' size='small' href='/client-list/cohorts'>
                        Manage Cohorts
                    </Button>
                </Box>
                <Box sx={{ marginTop: '1em', marginBottom: '1em' }}>
                    <Link href={'/system-settings/usac-data-stats'}>USAC Data Stats</Link>
                </Box>

                <Box sx={{ marginTop: '2em', marginBottom: '2em' }}>
                    process.env.NODE_ENV = <b>{process.env.NODE_ENV}</b>
                    <br />
                    process.env.REACT_APP_BUILD_ENV = <b>{process.env.REACT_APP_BUILD_ENV}</b>
                    <br />
                    const BUILD_ENV = <b>{BUILD_ENV}</b>
                    <br />
                </Box>
            </>
        );
    } else {
        return <></>;
    }
}

export default SystemSettings;
